<script>
import { required } from "vuelidate/lib/validators";

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Stat from "@/components/widgets/stat.vue";
import {getBrokerageApi} from '@/api/brokerage'
import { financial } from '@/api/misc'
/**
 * User list component
 */
export default {
  page: {
    title: "Agent List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      contactListData: [],
      title: "Agent List",
      items: [
        {
          text: "Brokerage",
          href: "/",
        },
        {
          text: "Agent List",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
       
        {
          key: "name",
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: "Ref",
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: "Vin",
          sortable: true,
          tdClass: 'align-middle'
        },

        {
          key: "Phone",
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: "Office",
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: "Balance",
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: "Action",
          sortable: true,
          tdClass: 'align-middle'
        },
      ],
      coinvalue: "",
      statusValue: "",
      typeValue: null,
      datepickervalue: "",
      searchKey: "",
      showModal: false,
      contact: {
        name: "",
        designation: "",
        email: "",
        file: "",
      },
      submitted: false,
      widgetData: [
      
        {
          icon: "uil-layer-group",
          title: "Total Agents",
          value: this.rows,
        },

      ],
    };
  },
  components: {
    Layout,
    PageHeader,
    Stat
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList()
  },
  mounted() {
  
  },
  methods: {


    f(v, d =2) {
      return financial(v, d)
    },

    queryList() {
      let data = {
        page : {page : this.currentPage, page_size: this.perPage}
      }
      if (this.searchKey) {
        data.search_key  = this.searchKey
      }
      getBrokerageApi().agentApi.list(data).then((res)=>{
        this.contactListData = []
        res.data.map((o) =>{
        
          this.contactListData.push({
            id: o.id,
            image: o.picture?  o.picture : require('@/assets/images/users/avatar-1.jpg'),
            name: o.full_name,
            vin: o.agent_vin,
            email: o.email,
            phone: o.phone,
            office: o.office_name,
            isSelected: false,
            licensed_number : o.licensed_number,
            ref_code : o.LWA_userName,
            balance  : o.balance
          })
        
        })
        this.widgetData[0].value =  res.page.total;
        this.totalRows = res.page.total;

      })
     
    },

    new_agent() {
      this.$router.push({name :'brokerage-agent-edit' })
    },

    edit_agent(agent) {
      this.$router.push({name :'brokerage-agent-edit', params: {agent_id : agent.id} })
    },

    terminate_agent(agent) {
      this.$router.push({name :'brokerage-agent-edit', params: {agent_id : agent.id} })
    },

    view_agent(agent) {
      this.$router.push({name :'brokerage-agent-view', params: {agent : agent} })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.contact.file = URL.createObjectURL(file);
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },


    onEnterSearch() {
      this.currentPage = 1
      this.queryList()
    },

  },
  validations: {
    contact: {
      name: { required },
      designation: { required },
      email: { required },
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <Stat :widgetData="widgetData" />
        </div>
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4">
                <div class="search-box ">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control bg-light border-light rounded"
                        v-model="searchKey"
                        placeholder="Search Agent..."
                        @keyup.enter="onEnterSearch"
                      />
                      <i class="uil uil-search search-icon"></i>
                    </div>
                  </div>
              </div>
              <div class="col-md-2"></div>
              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2"
                >
                
                  <a
                    href="javascript:void(0);"
                    class="btn btn-light"
                    data-bs-toggle="modal"
                    @click="new_agent"
                    ><i class="uil uil-plus me-1"></i> Add Agent</a
                  >
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card header -->
          <div class="card-body">


            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="contactListData"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  class="table-check"
                >

                  <template #cell(name)="data">
                    
                    <router-link
                      :to="{name : 'brokerage-agent-view', query:{agent_id : data.item.id+''}}"
                      class="text-body fw-medium"
                      >{{ data.item.name }}</router-link
                    >
                  </template>
                  <template #cell(Ref)="data">
                    {{ data.item.ref_code }}
                  </template>
                  <template #cell(Vin)="data">
                    {{ data.item.vin }}
                  </template>

                  
                  <template #cell(Licence)="data">
                    {{ data.item.licensed_number }}
                  </template>

                  <template #cell(email)="data">
                    {{ data.item.email }}
                  </template>

                  <template #cell(phone)="data">
                    {{ data.item.phone }}
                  </template>

                  <template #cell(office)="data">
                    {{ data.item.office }}
                  </template>

                  <template #cell(Balance)="data">
                    ${{ f(data.item.balance).toLocaleString() }}
                  </template>

                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item @click="edit_agent(data.item)">Edit</b-dropdown-item>
                      <b-dropdown-item @click="$router.push({name : 'brokerage-agent-termination', query: {agent_code: data.item.vin, agent_id : data.item.id}})">Terminate </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
